<template>
  <v-card class="ma-4" flat>
    <v-row :class="darkmode || 'white'">
      <v-col cols="12" sm="9">
        <v-tabs v-model="tab" center-active @change="tabValue">
          <v-tab>
            {{ $t("absent.absent_per_day") }}
          </v-tab>
          <v-tab>
            {{ $t("absent.absent_per_subjects") }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <v-row>
          <v-col cols="12" sm="3">
            <div class="py-5">
              <v-menu
                v-model="modalDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormat"
                    v-on="on"
                    v-bind="attrs"
                    persistent-hint
                    outlined
                    dense
                    readonly
                    hide-details
                    append-icon="mdi-calendar"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  type="month"
                  @input="modalDate = false"
                />
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12">
            <ReportAbsent :idMonth="month" :year="year" :idPerson="person" />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <Table class="mt-5" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import moment from "moment/moment";
import { mapGetters } from "vuex";

export default {
  components: {
    ReportAbsent: () => import("@/components/ReportAbsent"),
    Table: () => import("./components/Table")
  },
  beforeMount() {
    this.person = `${this.g_user_id}`;
    this.month = moment().format("MM");
    this.year = moment().format("YYYY");
    this.dateFormat = `${moment(this.month, "M").format("MMMM")} ${this.year}`;
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    },
    ...mapGetters(["g_user_id"])
  },
  watch: {
    date(value) {
      this.year = value.substr(0, 4);
      this.month = value.substr(-2);
      this.dateFormat = `${moment(this.month, "M").format("MMMM")} ${
        this.year
      }`;
    }
  },
  data() {
    return {
      search: "",
      tab: this.$route.query.tab | 0,
      year: "",
      month: "",
      person: "",
      date: new Date().toISOString().substr(0, 7),
      modalDate: false,
      dateFormat: ""
    };
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "RecapAbsent", query: { tab: tab } });
    }
  }
};
</script>
